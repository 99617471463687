import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { useQueryParams } from 'hooks'
import { useClientSourcedAttachmentsFetcher } from 'apis'
import View from './View'
import useCRUDVendorAttachment from './useCRUDVendorAttachment'

const Container = ({ vendorId, clientVendorId }) => {
  const {
    createVendorAttachments,
    editVendorAttachment,
    deleteVendorAttachment,
  } = useCRUDVendorAttachment(clientVendorId)
  const [queryParams, setQueryParams] = useQueryParams({
    page: {
      type: Number,
      defaultValue: 1,
    },
    sizePerPage: {
      queryKey: 'per_page',
      type: Number,
      defaultValue: config.collectionQueryPageLimit.default,
    },
    sortField: {
      queryKey: 'ordered_by',
    },
    sortOrder: {
      queryKey: 'direction',
    },
    searchText: {
      queryKey: 'q',
      defaultValue: '',
    },
  })
  const [sourceTypes, setSourceTypes] = useState({
    selected: ['vendor'],
  })
  const {
    data: attachments,
    totalSize,
    isFetching,
  } = useClientSourcedAttachmentsFetcher({
    params: {
      page: queryParams.page,
      per_page: queryParams.sizePerPage,
      search_query: queryParams.searchText,
      ordered_by: queryParams.sortField,
      direction: queryParams.sortOrder,
      source_type: !sourceTypes.selected.length
        ? ['none']
        : sourceTypes.selected,
      vendor_id: vendorId,
    },
  })

  const handleSearchChange = useCallback(
    (newValue) => {
      setQueryParams({
        page: 1,
        searchText: newValue,
      })
    },
    [setQueryParams],
  )

  const handleEdit = useCallback(
    (cvaId) => {
      const row = attachments?.find((e) => e?.cva?.id === cvaId)
      if (!row) {
        return
      }

      const { cva, ...rest } = row
      const vendorAttachment = {
        ...cva,
        attachment: row.source_id ? rest : undefined,
      }
      editVendorAttachment(vendorAttachment)
    },
    [editVendorAttachment, attachments],
  )

  return (
    <View
      attachments={attachments}
      totalSize={totalSize}
      loading={isFetching}
      page={queryParams.page}
      sizePerPage={queryParams.sizePerPage}
      sortField={queryParams.sortField}
      sortOrder={queryParams.sortOrder}
      searchText={queryParams.searchText}
      sourceTypes={sourceTypes}
      vendorColumnVisible={!vendorId}
      newFileButtonVisible={!!clientVendorId}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onSourceTypesChange={setSourceTypes}
      onBulkCreate={createVendorAttachments}
      onEdit={handleEdit}
      onDelete={deleteVendorAttachment}
    />
  )
}

Container.propTypes = {
  vendorId: PropTypes.string,
  clientVendorId: PropTypes.string,
}

export default Container
