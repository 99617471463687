import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { FieldArray } from 'formik'
import uniqueId from 'lodash/uniqueId'
import { PromisePool } from '@supercharge/promise-pool'
import A from 'components-v2/atoms/A'
import DropzoneUploader from 'components-v2/molecules/AttachFilesModal/DropzoneUploader'
import FormDialog from 'components-v2/organisms/FormDialog'
import { useCreateClientVendorAttachment } from 'apis'
import config from 'config'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import { pluralize } from 'utils/string'
import FileFieldGroup from './FileFieldGroup'
import { Progress, Text, Wrapper } from './styles'

const batchSize = 3

const eachFileSchema = yup.object().shape({
  file: yup.mixed().nullable(),
  existing_document_url: yup.string().when('file', {
    is: undefined,
    then: yup
      .string()
      .nullable()
      .trim()
      .url('Please enter a valid url')
      .required('Please set an existing document url'),
  }),
})

const validationSchema = yup.object().shape({
  files: yup.array().min(1, 'Please add at least 1 file').of(eachFileSchema),
})

const formConfig = {
  initialValues: {
    files: [],
  },
  validationSchema,
}

const UploadBulkFilesModal = ({ clientVendorId, onSuccess, ...rest }) => {
  const { mutateAsync: createClientVendorAttachment } =
    useCreateClientVendorAttachment()
  const formikRef = React.useRef()
  const fieldArrayHelpersRef = React.useRef()
  const [uploadingState, setUploadingState] = React.useState({
    total: 0,
    uploading: 0,
  })

  const handleSubmit = React.useCallback(
    async ({ files }) => {
      try {
        setUploadingState({ total: files.length, uploading: 0 })
        const errors = []
        await PromisePool.withConcurrency(batchSize)
          .for(files)
          .handleError(async (error) => {
            errors.push(error)
          })
          .process(({ file, key, ...restFields }) => {
            setUploadingState((current) => ({
              total: files.length,
              uploading: current.uploading + 1,
            }))
            const payload = objectToFormData({
              client_vendor_attachment: {
                ...restFields,
                client_vendor_id: clientVendorId,
                attachment_attributes: file ? { file } : undefined,
              },
            })
            return createClientVendorAttachment({ data: payload }).then(() => {
              if (formikRef.current && fieldArrayHelpersRef.current) {
                const index = formikRef.current.values.files.findIndex(
                  (e) => e.key === key,
                )
                fieldArrayHelpersRef.current.remove(index)
              }
            })
          })

        if (errors.length > 0) {
          NotificationManager.error(
            errors.length > 1
              ? `${errors.length} uploads have failed.`
              : '1 upload has failed.',
          )
        } else {
          NotificationManager.success(
            files.length > 1
              ? `${files.length} files have been uploaded successfully.`
              : '1 file has been uploaded successfully.',
          )
          onSuccess()
        }
      } catch (error) {
        console.error(error)
        NotificationManager.error()
      }
    },
    [clientVendorId, createClientVendorAttachment, onSuccess],
  )

  const handleDisableSubmitCheck = (values) => values.files.length === 0

  return (
    <FormDialog
      {...rest}
      formConfig={{ ...formConfig, innerRef: formikRef }}
      title="Upload Files"
      size="lg"
      onSubmit={handleSubmit}
      disableSubmit={handleDisableSubmitCheck}
    >
      {({ values, isSubmitting }) => (
        <FieldArray
          name="files"
          render={(arrayHelpers) => {
            fieldArrayHelpersRef.current = arrayHelpers
            return (
              <>
                <DropzoneUploader
                  maxFiles={config.uploadLimits.maxFiles - values.files.length}
                  disabled={
                    isSubmitting ||
                    values.files.length >= config.uploadLimits.maxFiles
                  }
                  onChangeStatus={({ file, remove }, status) => {
                    if (status === 'done') {
                      arrayHelpers.push({ file, key: uniqueId() })
                      remove()
                    }
                  }}
                />
                <Wrapper>
                  <Text>
                    Or{' '}
                    <A
                      disabled={
                        values.files.length >= config.uploadLimits.maxFiles
                      }
                      onClick={() => {
                        arrayHelpers.push({
                          existing_document_url: '',
                          key: uniqueId(),
                        })
                      }}
                    >
                      link to existing document
                    </A>
                  </Text>
                </Wrapper>
                {values.files.map((file, index) => (
                  <FileFieldGroup
                    key={file.key}
                    name={`files.${index}`}
                    disabled={isSubmitting}
                    onDelete={() => arrayHelpers.remove(index)}
                  />
                ))}
                {isSubmitting && (
                  <Progress>
                    Uploading {uploadingState.uploading} of{' '}
                    {pluralize(uploadingState.total, 'possible upload')}...
                  </Progress>
                )}
              </>
            )
          }}
        />
      )}
    </FormDialog>
  )
}

UploadBulkFilesModal.propTypes = {
  clientVendorId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default UploadBulkFilesModal
